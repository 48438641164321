import React from 'react';
import { FaTwitter, FaLinkedinIn, FaGithub, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-to-br from-gray-900 to-gray-800 text-white py-16">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
          <div>
            <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-green-400">Agentix</h3>
            <p className="text-gray-300 mb-4">AI Agent for your business</p>
            <div className="flex space-x-4">
              <a href="https://x.com/brunoergang" className="text-gray-400 hover:text-white transition-colors duration-300">
                <FaXTwitter size={20} />
              </a>
              <a href="https://www.linkedin.com/in/bruno-ergang-25ba0b250/" className="text-gray-400 hover:text-white transition-colors duration-300">
                <FaLinkedinIn size={20} />
              </a>
            </div>
          </div>
          {['Company', 'Product'].map((title) => (
            <div key={title}>
              <h4 className="text-lg font-semibold mb-4 text-gray-100">{title}</h4>
              <ul className="space-y-2">
                {(title === 'Company'
                  ? ['Terms of Service', 'Privacy Policy', 'Contact']
                  : ['Pricing', 'Use Cases', 'How It Works', 'Features']
                ).map((item) => (
                  item === 'Contact' ? (
                    <li key={item}>
                      <span className="text-gray-400 inline-block">
                        Contact: support@useagentix.com
                      </span>
                    </li>
                  ) : (
                    <li key={item}>
                      <a href={item === 'Terms of Service' || item === 'Privacy Policy' ? `/pages/${item.replace(/\s+/g, '').toLowerCase()}` : `#${item.replace(/\s+/g, '').toLowerCase()}`} className="text-gray-400 hover:text-white transition-colors duration-300 hover:translate-x-1 inline-block">
                        {item}
                      </a>
                    </li>
                  )
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="border-t border-gray-700 pt-8 text-center">
          <p className="text-gray-400 text-sm">
            © {new Date().getFullYear()} Agentix. All rights reserved.
          </p>
          <div className="mt-4 space-x-4">
            <a href="#" className="text-sm text-gray-400 hover:text-white transition-colors duration-300">Privacy Policy</a>
            <a href="#" className="text-sm text-gray-400 hover:text-white transition-colors duration-300">Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;