import React from 'react';
import { motion } from 'framer-motion';

const TrustedCompanies: React.FC = () => {
  const companies = ['anthropic.png', 'groqlogo.png', 'msftpro.png', 'pineconelogoss.png', 'coherelogo.png'];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100
      }
    }
  };

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Trusted by Industry Leaders</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Join the ranks of innovative companies leveraging our AI solutions to transform their businesses.
          </p>
        </div>
        <motion.div 
          className="flex flex-wrap justify-center items-center gap-12 md:gap-16"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {companies.map((company, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105"
              variants={itemVariants}
            >
              <img 
                src={require(`../images/${company}`)} 
                alt={`Company ${index + 1}`} 
                className="h-16 md:h-20 w-auto object-contain"
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default TrustedCompanies;