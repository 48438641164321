import React from 'react';

const CallToAction: React.FC = () => {
  return (
    <section className="py-24 bg-gradient-to-r from-blue-600 to-indigo-700 text-white">
      <div className="max-w-5xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-6 leading-tight">Ready to Revolutionize Your Business?</h2>
        <p className="text-xl md:text-2xl mb-10 opacity-90">Use AI agents to improve your business operations.</p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
          <a href="https://app.useagentix.com" className="bg-white text-blue-600 px-8 py-4 rounded-full font-bold text-lg hover:bg-blue-100 transition duration-300 shadow-lg transform hover:scale-105">
            Start Your Free Trial
          </a>
        </div>
        <p className="mt-8 text-sm opacity-75">No credit card required. Start for Free.</p>
      </div>
    </section>
  );
};

export default CallToAction;