import React from 'react';
import { FaComments, FaUsers, FaStar, FaServer } from 'react-icons/fa';

const StatItem: React.FC<{ value: string; label: string; icon: React.ReactNode; color: string }> = ({ value, label, icon, color }) => (
  <div className={`text-center p-6 rounded-lg shadow-lg ${color}`}>
    <div className="text-white text-4xl mb-4">{icon}</div>
    <p className="text-4xl font-bold mb-2 text-white">{value}</p>
    <p className="text-lg text-white opacity-80">{label}</p>
  </div>
);

const Stats: React.FC = () => {
  const stats = [
    { value: '200k+', label: 'Queries answered', icon: <FaComments />, color: 'bg-gradient-to-r from-blue-500 to-blue-600' },
    { value: '500+', label: 'Satisfied customers', icon: <FaUsers />, color: 'bg-gradient-to-r from-green-500 to-green-600' },
    { value: '4.9/5', label: 'Customer satisfaction', icon: <FaStar />, color: 'bg-gradient-to-r from-yellow-500 to-yellow-600' },
    { value: '100%', label: 'Uptime', icon: <FaServer />, color: 'bg-gradient-to-r from-purple-500 to-purple-600' },
  ];

  return (
    <section className="py-20 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">Our Impact in Numbers</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <StatItem key={index} {...stat} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Stats;