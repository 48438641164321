import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuoteLeft, FaChevronLeft, FaChevronRight, FaStar } from 'react-icons/fa';

const TestimonialCard: React.FC<{ quote: string; author: string; company: string; rating: number }> = ({ quote, author, company, rating }) => (
  <motion.div
    className="bg-white rounded-2xl shadow-xl p-8 md:p-12"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex justify-center mb-6">
      <div className="bg-blue-500 text-white rounded-full p-3">
        <FaQuoteLeft className="text-3xl" />
      </div>
    </div>
    <p className="text-gray-700 text-lg md:text-xl mb-6 italic leading-relaxed text-center">{quote}</p>
    <div className="text-center">
      <h3 className="font-bold text-gray-900 text-xl mb-1">{author}</h3>
      <p className="text-blue-600 mb-2">{company}</p>
      <div className="flex justify-center text-yellow-400">
        {[...Array(5)].map((_, i) => (
          <FaStar key={i} className={i < rating ? 'text-yellow-400' : 'text-gray-300'} />
        ))}
      </div>
    </div>
  </motion.div>
);

const Testimonials: React.FC = () => {
  const testimonials = [
    { quote: "Agentix has revolutionized our customer support. We've experienced a 45% boost in customer satisfaction scores and cut response times in half. It's like having a tireless, knowledgeable assistant available 24/7.", author: "Mark Stonies", company: "Head of Technology at Steelen Industries", rating: 5 },
    { quote: "This AI agent is a game-changer for our sales process. We're now converting leads 60% faster, and the quality of interactions has improved dramatically. Our team can focus on high-value tasks while Agentix handles the initial outreach.", author: "Marcus Lopaz", company: "Founder of Connex", rating: 5 },
    { quote: "Implementing Agentix was the best decision we made this year. It's streamlined our workflow, reduced errors by 70%, and allowed us to scale our operations without increasing headcount. The ROI has been phenomenal.", author: "Sara Pepperlee", company: "CEO @ Helios UI", rating: 5 },
    { quote: "As a startup, efficiency is crucial. Agentix has been like adding a whole new department to our team. It handles customer queries, schedules meetings, and even helps with basic market research. It's an indispensable tool for us now.", author: "Curtis Fletcher", company: "Business Operations at Suppo", rating: 5 },
    { quote: "The natural language processing capabilities of Agentix are truly impressive. It understands context and nuance in a way that has significantly improved our customer interactions. We've seen a 50% increase in positive feedback since implementation.", author: "Olivia Pionse", company: "Head of Customer PampaVerde", rating: 5 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="py-20 bg-gradient-to-br from-gray-50 to-white-100">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Voices of Success</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover how our AI solution is transforming businesses and delighting customers across industries.
          </p>
        </div>
        <div className="relative">
          <div className="max-w-4xl mx-auto">
            <div className="flex flex-col items-center justify-center">
              <div className="w-full h-96">
                <AnimatePresence mode="wait">
                  <TestimonialCard key={currentIndex} {...testimonials[currentIndex]} />
                </AnimatePresence>
              </div>
              <div className="flex justify-center mt-8">
                <button
                  onClick={prevTestimonial}
                  className="mx-2 bg-white text-blue-600 p-4 rounded-full shadow-md hover:bg-blue-600 hover:text-white transition duration-300 focus:outline-none"
                  aria-label="Previous testimonial"
                >
                  <FaChevronLeft className="text-2xl" />
                </button>
                <button
                  onClick={nextTestimonial}
                  className="mx-2 bg-white text-blue-600 p-4 rounded-full shadow-md hover:bg-blue-600 hover:text-white transition duration-300 focus:outline-none"
                  aria-label="Next testimonial"
                >
                  <FaChevronRight className="text-2xl" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;