import React from 'react';
import Hero from './Hero';
import DashboardPreview from './DashboardPreview';
import { motion } from 'framer-motion';

const HeroSection: React.FC = () => {
  return (
    <section className="bg-white py-20 lg:py-32 px-4 relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <motion.div 
            className="lg:w-5/12 lg:pr-8 mb-12 lg:mb-0"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Hero />
          </motion.div>
          <motion.div 
            className="lg:w-7/12 lg:pl-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <DashboardPreview />
            <motion.div 
              className="mt-6 flex flex-col items-center justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <div className="flex items-center justify-center bg-gray-50 px-6 py-3 rounded-full shadow-lg">
                <div className="flex text-yellow-400 text-2xl mr-3">
                  ★★★★★
                </div>
                <span className="text-lg text-gray-700 font-semibold">4.9/5 from 500+ businesses</span>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;