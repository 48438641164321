import React from 'react';
import { FaRegLightbulb, FaBolt, FaRocket, FaGem, FaCheck } from 'react-icons/fa';

const PricingTier: React.FC<{ 
  name: string; 
  price: string; 
  features: string[]; 
  highlighted?: boolean;
  icon: React.ReactNode;
}> = ({ name, price, features, highlighted, icon }) => (
  <div className={`bg-white p-8 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 ${highlighted ? 'border-2 border-blue-500 relative' : ''}`}>
  {highlighted && (
    <div className="absolute top-0 right-0 bg-blue-500 text-white py-1 px-3 rounded-bl-lg text-sm font-semibold">
      Popular
    </div>
  )}
  <div className={`w-20 h-20 mx-auto mb-6 rounded-full flex items-center justify-center ${highlighted ? 'bg-blue-500 text-white' : 'bg-blue-100 text-blue-500'}`}>
    {icon}
  </div>
    <h3 className="text-2xl font-bold mb-4 text-center text-gray-800">{name}</h3>
    <p className="text-4xl font-bold mb-6 text-center text-blue-600">${price}<span className="text-sm font-normal text-gray-500">/mo</span></p>
    <ul className="mb-8 space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-gray-600">
          <FaCheck className="text-green-500 mr-2 flex-shrink-0" /> 
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <button 
      onClick={() => window.location.href = 'https://app.useagentix.com'}
      className={`w-full py-3 rounded-lg font-semibold transition-colors duration-300 ${
        highlighted 
          ? 'bg-blue-500 text-white hover:bg-blue-600' 
          : 'bg-blue-100 text-blue-500 hover:bg-blue-200'
      }`}
    >
      {highlighted ? 'Get Started Now' : 'Choose Plan'}
    </button>
  </div>
);

const Pricing: React.FC = () => {
  const plans = [
    { 
      name: 'Free', 
      price: '0', 
      features: ['1 Agent', '5 Sources', '500,000 Characters', '50 Messages', '5 Links to train', 'Inbox', 'Dashboards'],
      icon: <FaRegLightbulb size={32} />
    },
    { 
      name: 'Starter', 
      price: '49', 
      features: ['2 Agents', '50 sources', '20,000,000 Characters', '4,000 Messages', '50 Links to train', 'Inbox', 'Dashboards'],
      icon: <FaBolt size={32} />
    },
    { 
      name: 'Pro', 
      price: '99', 
      features: ['5 Agents', '100 Sources', '50,000,000 Characters', '10,000 Messages', '100 Links to train', 'Inbox', 'Dashboards', 'Agentic features (Soon)'],
      icon: <FaRocket size={32} />
    },
    { 
      name: 'Business', 
      price: '499', 
      features: ['10 Agents', 'Unlimited Sources', 'Unlimited Characters', 'Unlimited Messages', 'Unlimited links to train', 'Inbox', 'Dashboards', 'Agentic features (Soon)'],
      icon: <FaGem size={32} />
    },
  ];

  return (
    <section id="pricing" className="py-20 bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-sm font-semibold text-blue-600 uppercase tracking-wide mb-2">Pricing Plans</h2>
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Choose the perfect plan for your needs</h1>
          <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500">
            All plans include advanced tools and features to boost your agent. Select the best option to fit your requirements and scale your business.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {plans.map((plan, index) => (
            <PricingTier 
              key={index} 
              {...plan} 
              highlighted={index === 2} // Highlight the Pro plan
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;