import React from 'react';
import { FaRobot, FaSync, FaPalette, FaShieldAlt, FaGlobe, FaChartLine } from 'react-icons/fa';

const FeatureCard: React.FC<{ title: string; description: string; icon: React.ReactNode }> = ({ title, description, icon }) => (
  <div className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
    <div className="w-full h-32 flex items-center justify-center bg-gradient-to-br from-blue-400 to-blue-600">
      <span className="text-5xl text-white">{icon}</span>
    </div>
    <div className="p-6">
      <h3 className="text-xl font-bold mb-3 text-gray-800">{title}</h3>
      <p className="text-gray-600 leading-relaxed">{description}</p>
    </div>
  </div>
);

const Features: React.FC = () => {
  const features = [
    {
      icon: <FaRobot />,
      title: "AI Agent",
      description: "Leverage cutting-edge AI technology for intelligent conversations and adaptive responses to user queries."
    },
    {
      icon: <FaSync />,
      title: "Custom Training",
      description: "Incorporate your own data to train and personalize your AI agent for unparalleled customization."
    },
    {
      icon: <FaPalette />,
      title: "Brand Customization",
      description: "Tailor your chatbot's appearance to align perfectly with your brand identity and web presence."
    },
    {
      icon: <FaShieldAlt />,
      title: "Enterprise-Grade Security",
      description: "Protect your data with state-of-the-art encryption and stringent access protocols."
    },
    {
      icon: <FaGlobe />,
      title: "Multilingual Support",
      description: "Communicate in any language, breaking down barriers between you and your global audience."
    },
    {
      icon: <FaChartLine />,
      title: "Advanced Analytics",
      description: "Gain deep insights into user engagement and AI performance through comprehensive analytics."
    }
  ];

  return (
    <section id="features" className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Powerful Features to Supercharge Your Business</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover how our cutting-edge AI solutions can transform your customer interactions and boost your efficiency.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard 
              key={index} 
              title={feature.title}
              description={feature.description}
              icon={feature.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;