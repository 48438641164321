import React from 'react';
import logoImage from '../images/logo.png';

const Header: React.FC = () => {
  return (
    <header className="fixed top-0 left-0 right-0 z-50">
      <div className="max-w-8xl mx-auto">
        <div className="flex justify-between items-center p-4 bg-white shadow-md">
          <div className="flex-1 flex justify-center md:justify-end lg:justify-center">
            <a href="/" className="flex items-center mr-4 md:mr-0 lg:mr-8">
              <img src={logoImage} alt="Agentix" className="h-9 w-auto" />
              <span className="ml-2 text-2xl font-bold text-black-600">Agentix</span>
            </a>
          </div>
          <nav className="flex-1">
            <ul className="flex justify-center space-x-4">
              <li><a href="#features" className="text-gray-600 hover:text-blue-600 font-bold">Features</a></li>
              <li><a href="#usecases" className="text-gray-600 hover:text-blue-600 font-bold">Use Cases</a></li>
              <li><a href="#howitworks" className="text-gray-600 hover:text-blue-600 font-bold">How it works</a></li>
              <li><a href="#pricing" className="text-gray-600 hover:text-blue-600 font-bold">Pricing</a></li>
            </ul>
          </nav>
          <div className="flex-1 flex justify-center md:justify-start lg:justify-center">
          <button
              className="bg-blue-500 hover:bg-gradient-to-r hover:from-blue-600 hover:to-green-500 text-white px-4 py-2 rounded font-bold transition duration-300 shadow-lg hover:shadow-xl transform hover:scale-105"
              onClick={() => window.location.href = 'https://app.useagentix.com'}
            >
              Access
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;