import React from 'react';
import dashboardImage from '../images/dashboards.jpg';
import { motion } from 'framer-motion';

const DashboardPreview: React.FC = () => (
  <motion.div 
    className="w-full rounded-2xl overflow-hidden shadow-2xl"
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.8 }}
  >
    <img 
      src={dashboardImage}
      alt="Dashboard Preview" 
      className="w-full object-cover object-top"
      style={{ height: '500px', width: '100%' }}
    />
  </motion.div>
);

export default DashboardPreview;