import React from 'react';
import { FaChartLine, FaSmile, FaPiggyBank, FaLightbulb } from 'react-icons/fa';

interface BenefitProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  stats: string;
  isReversed: boolean;
}

const BenefitItem: React.FC<BenefitProps> = ({ title, description, icon, stats, isReversed }) => (
  <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} items-center mb-24`}>
    <div className="md:w-1/2 mb-8 md:mb-0">
      <div className="bg-gradient-to-br from-blue-400 to-blue-600 rounded-2xl shadow-2xl p-8 transform hover:scale-105 transition-transform duration-300">
        <div className="text-white text-6xl mb-4">{icon}</div>
        <div className="text-white text-4xl font-bold">{stats}</div>
      </div>
    </div>
    <div className={`md:w-1/2 ${isReversed ? 'md:pr-16' : 'md:pl-16'}`}>
      <h3 className="text-3xl font-bold mb-4 text-gray-800">{title}</h3>
      <p className="text-xl text-gray-600 leading-relaxed">{description}</p>
    </div>
  </div>
);

const Benefits: React.FC = () => {
  const benefits = [
    {
      title: "Increased Revenue Generation",
      description: "Our AI-powered solution helps you convert more leads into sales, significantly boosting your revenue. Experience the power of intelligent customer interactions.",
      icon: <FaChartLine />,
      stats: "Up to 30% boost in revenue"
    },
    {
      title: "Enhanced Customer Satisfaction",
      description: "Deliver instant, accurate responses to your customers, leading to higher satisfaction rates and increased loyalty. Happy customers mean a thriving business.",
      icon: <FaSmile />,
      stats: "95% customer satisfaction"
    },
    {
      title: "Reduced Operational Costs",
      description: "Streamline your customer service operations while maintaining high-quality support. Our AI solution helps you save on operational costs without compromising on service.",
      icon: <FaPiggyBank />,
      stats: "Save up to 40% on costs"
    },
    {
      title: "Improved Decision Making",
      description: "Harness the power of AI-generated insights to make data-driven decisions. Optimize your strategies and achieve a higher return on investment with intelligent analytics.",
      icon: <FaLightbulb />,
      stats: "20% increase in ROI"
    },
  ];

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Unlock the Power of AI</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover how our AI solution can transform your business, boost your revenue, and delight your customers.
          </p>
        </div>
        {benefits.map((benefit, index) => (
          <BenefitItem
            key={index}
            title={benefit.title}
            description={benefit.description}
            icon={benefit.icon}
            stats={benefit.stats}
            isReversed={index % 2 !== 0}
          />
        ))}
      </div>
    </section>
  );
};

export default Benefits;