import React from 'react';
import { FaHeadset, FaBullseye, FaBook, FaChartLine, FaGlobe, FaChartBar, FaGraduationCap, FaShieldAlt } from 'react-icons/fa';

interface UseCaseProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const UseCaseItem: React.FC<UseCaseProps> = ({ icon, title, description }) => (
  <div className="flex flex-col items-start p-6 bg-white rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
    <div className="w-16 h-16 flex items-center justify-center bg-gradient-to-br from-blue-400 to-blue-600 rounded-full text-white text-3xl mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-bold mb-3 text-gray-800">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </div>
);

const UseCases: React.FC = () => {
  const useCases: UseCaseProps[] = [
    {
      icon: <FaHeadset />,
      title: "Customer Support",
      description: "Enhance satisfaction with quick, accurate responses and seamless human escalation."
    },
    {
      icon: <FaBullseye />,
      title: "Lead Generation",
      description: "Convert visitors into qualified leads through intelligent conversations."
    },
    {
      icon: <FaBook />,
      title: "Knowledge Assistant",
      description: "Empower your team with instant access to company information and resources."
    },
    {
      icon: <FaChartLine />,
      title: "Sales Automation",
      description: "Streamline sales by automating interactions, qualifying leads, and scheduling appointments."
    },
    {
      icon: <FaGlobe />,
      title: "Multilingual Support",
      description: "Break language barriers and expand your reach to global markets."
    },
    {
      icon: <FaChartBar />,
      title: "Market Research",
      description: "Gather valuable insights through interactive surveys and data analysis."
    },
    {
      icon: <FaGraduationCap />,
      title: "User Onboarding",
      description: "Streamline customer onboarding with personalized guidance and support."
    },
    {
      icon: <FaShieldAlt />,
      title: "Customer Retention",
      description: "Foster long-term customer relationships through proactive support and engagement."
    }
  ];

  return (
    <section id="usecases" className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Versatile AI Solutions for Every Need</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover how our AI can revolutionize various aspects of your business, from customer support to compliance management.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {useCases.map((useCase, index) => (
            <UseCaseItem key={index} {...useCase} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCases;