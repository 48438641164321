import React from 'react';
import { motion } from 'framer-motion';

const Hero: React.FC = () => {
  return (
    <div>
      <motion.h1 
        className="text-5xl lg:text-6xl xl:text-7xl font-extrabold mb-6 leading-tight"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-500 animate-gradient-x">
          AI Customer Assistant
        </span>
        <br />
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-blue-600 animate-gradient-x">
          <span className="text-gray-900"> for your </span> business
        </span>
      </motion.h1>
      <motion.p 
        className="text-xl lg:text-2xl mb-10 text-gray-600"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Agentix, your AI customer assisant. <br />
        Ready to serve, 24/7.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <button 
          onClick={() => window.location.href = 'https://app.useagentix.com'}
          className="bg-gradient-to-r from-blue-500 to-green-400 text-white px-10 py-4 rounded-full text-xl lg:text-2xl font-semibold hover:from-blue-600 hover:to-green-500 transition duration-300 shadow-lg transform hover:scale-105"
        >
          Get started now
        </button>
        <p className="text-lg text-gray-500 mt-1 ml-8">No credit card required</p>
      </motion.div>
    </div>
  );
};

export default Hero;