import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import DashboardPreview from './components/DashboardPreview';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import TrustedCompanies from './components/TrustedCompanies';
import Pricing from './components/Pricing';
import Stats from './components/Stats';
import FAQ from './components/FAQ';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import Benefits from './components/Benefits';
import UseCases from './components/UseCases';
import HowItWorks from './components/HowItWorks';

const App: React.FC = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16">
        <HeroSection />
        <Features />
        <HowItWorks />
        <Benefits />
        <TrustedCompanies />
        <UseCases />
        <Testimonials />
        <Pricing />
        <Stats />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default App;